import { useState } from "react";
import { useLoaderData } from "react-router";
import Button from "~/components/button";
import ButtonLink from "~/components/button-link";
import { IconAdd, IconKanban } from "~/components/icons";
import Board from "~/components/kanban/board";
import CardForm from "~/components/kanban/form";
import KanbanList from "~/components/kanban/list";
import KanbanBoardPanelButtons from "~/components/kanban/panel-buttons";
import Panel from "~/components/panel";
import type { AllKanbanCardsQuery } from "~/types/api";
import { useCurrentUser } from "~/utils/auth";

export default function IndividualBoard() {
  const {
    kanbanData: { kanbanCards, user }
  } = useLoaderData<{ kanbanData: AllKanbanCardsQuery }>();
  const currentUser = useCurrentUser();
  const [mode, setMode] = useState("Board");
  const [adding, setAdding] = useState("");
  const [filter, setFilter] = useState("");

  return (
    <Panel>
      <Panel.Header
        title={`${
          currentUser.id === user!.id ? "My" : `${user!.fullname}'s`
        } Kanban Cards`}
        floatButton
        button={
          <div className="flex items-center space-x-2">
            <KanbanBoardPanelButtons
              mode={mode}
              setMode={setMode}
              setFilter={setFilter}
            />
            <ButtonLink to="/kanban" small>
              <IconKanban /> View Company Board
            </ButtonLink>
            <Button onClick={() => setAdding("N/A")} small>
              <IconAdd /> Add a Card
            </Button>
          </div>
        }
      />
      {mode === "Board" ? (
        <Board
          cards={kanbanCards}
          panel
          showParent
          hideUser
          onAdd={setAdding}
          filter={filter}
          openBacklog={() => setMode("Backlog")}
        />
      ) : (
        <KanbanList
          cards={kanbanCards}
          filter={filter}
          backlog={mode === "Backlog"}
        />
      )}
      {adding && (
        <CardForm
          column={adding}
          userId={user?.id}
          onClose={() => setAdding("")}
        />
      )}
    </Panel>
  );
}
